import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { Language } from '../data/languages';
import { join } from 'path';

type Props = {
  description: string;
  lang: Language;
  meta?: HTMLMetaElement[];
  title: string;
  image?: string;
  twitterImage?: string;
  pathname: string;
};

function SEO({
  description,
  lang,
  meta,
  title,
  image,
  twitterImage,
  pathname,
}: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title {
              ja
            }
            description {
              ja
            }
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description[lang];

  let imageMeta: any[] = [];
  if (image) {
    imageMeta.push({
      property: 'og:image',
      content: `https://${join('panbanisha.com', image)}`,
    });
  }

  if (twitterImage) {
    imageMeta.push({
      name: 'twitter:image',
      content: `https://${join('panbanisha.com', twitterImage)}`,
    });
  }

  const titleFormat = !!title
    ? `${title} | ${site.siteMetadata.title[lang]}`
    : site.siteMetadata.title[lang];

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={titleFormat}
      defer={false}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `https://${join('panbanisha.com', pathname)}`,
        },
        {
          property: `og:title`,
          content: titleFormat,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: titleFormat,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(imageMeta)
        .concat(meta ?? [])}
    />
  );
}

export default SEO;
