import * as React from 'react';
import { ReactNode } from 'react';
import { Link as CustomLink } from 'gatsby';

type LinkType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  duration?: number;
  fade?: boolean;
  cover?: boolean;
  paintDrip?: boolean;
  direction?: 'up' | 'right' | 'down' | 'left';
  activeClassName?: string;
  to: string;
  children: ReactNode;
};
export const Link = ({
  className,
  to,
  duration,
  fade,
  cover,
  paintDrip,
  direction,
  children,
  activeClassName,
}: LinkType) => (
  <CustomLink
    to={to}
    // fade={fade}
    // paintDrip={true}
    // cover={cover}
    // duration={duration || 1}
    // direction={direction}
    activeClassName={activeClassName || 'active'}
    className={className}
  >
    {children}
  </CustomLink>
);
