import * as React from 'react';
import tw from 'twin.macro';
import { Language } from '../data/languages';
import { getHeaderNavItems } from '../../models';
import { Link } from '../utils/link';

type Props = {
  siteTitle: string;
  lang: Language;
  pathnameWithoutLang?: string;
};

const Header = ({ lang, pathnameWithoutLang }: Props) => {
  const isTop = pathnameWithoutLang === '/';
  const navItems = getHeaderNavItems();
  return (
    <header
      css={tw`flex flex-wrap items-stretch overflow-hidden fixed right-0 z-10 top-0`}
    >
      <div
        css={tw`flex m-0 p-0 list-none ml-auto bg-white bg-opacity-10`}
        id="menu"
      >
        <nav
          css={tw`h-full fixed w-full top-0 left-0 z-10 z-auto w-auto static`}
        >
          <ul
            css={tw`flex justify-center items-center h-full lg:h-auto m-0 p-0 list-none ml-auto bg-gray-400 bg-white bg-opacity-10`}
          >
            {navItems.map((entry) => (
              <li
                css={tw`flex items-stretch m-0 p-0 lg:first:pl-6 lg:last:pr-6 mb-0`}
                key={entry.path}
              >
                <Link
                  css={tw`flex w-full items-center px-6 py-3 text-lg italic hover:opacity-50 text-title font-medium`}
                  to={entry.id}
                >
                  <span css={isTop ? tw`lg:text-white` : tw``}>
                    {entry.displayName}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
