import homeOgImage from '../images/ogp.png';
import homeOgTwitterImage from '../images/ogp-twitter.png';
import contactOgImage from '../images/ogp-contact.png';
import contactOgTwitterImage from '../images/ogp-contact-twitter.png';

type SEOData = {
  [page: string]: {
    title: {
      [lang: string]: string;
    };
    description: {
      [lang: string]: string;
    };
    image?: {
      [lang: string]: string;
    };
    twitterImage?: {
      [lang: string]: string;
    };
  };
};

export const seoData: SEOData = {
  home: {
    title: {
      ja: '',
    },
    description: {
      ja:
        'Panbanishaは撮影の前準備であるシナリオの作成、香盤表作成、最適なクルーの準備などの撮影の前段階から編集、MA、BGMの作成など、映像表現に付随するクリエイティブまでワンストップで行う映像制作会社です。',
    },
    image: {
      ja: homeOgImage,
    },
    twitterImage: {
      ja: homeOgTwitterImage,
    },
  },
  contact: {
    title: {
      ja: 'Contact',
    },
    description: {
      ja:
        'お問い合わせは、下記フォームをご利用ください。情報をご入力の上、「送信する」をクリックしてください。数日以内に弊社からご連絡いたします。',
    },
    image: {
      ja: contactOgImage,
    },
    twitterImage: {
      ja: contactOgTwitterImage,
    },
  },
  notFound: {
    title: {
      ja: '404 Not Found',
    },
    description: {
      ja: '404 Not Found',
    },
    image: {
      ja: homeOgImage,
    },
    twitterImage: {
      ja: homeOgTwitterImage,
    },
  },
};
