import * as React from 'react';
import tw from 'twin.macro';
import ContentWidth from './contentWidth';

const Footer = () => {
  return (
    <footer css={tw`p-10 overflow-hidden`} id="footer">
      <ContentWidth>
        <small css={tw`text-center text-title italic block`}>
          <span>
            © {new Date().getFullYear()} Panbanisha Inc. All Rights Reserved
          </span>
        </small>
      </ContentWidth>
    </footer>
  );
};

export default Footer;
