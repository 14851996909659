'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.socialMedias = exports.getHeaderNavItems = exports.getFooterNavItems = exports.getNavItems = exports.models = void 0;
exports.models = {
  // dynamic: {
  //   blogPost: {
  //     path: '/posts/',
  //     showNav: false,
  //     showFooterNav: false,
  //     showHeaderNav: false,
  //     displayName: '',
  //     navOrder: 4,
  //     template: path.resolve('src/templates/post.tsx'),
  //     limit: 1000,
  //     node: ['id', 'slug'],
  //     isSingle: false,
  //   },
  // },
  // static: {
  //   blogPosts: {
  //     path: '/posts/',
  //     showNav: true,
  //     showFooterNav: true,
  //     showHeaderNav: true,
  //     displayName: 'BLOG',
  //     navOrder: 4,
  //     template: path.resolve('src/templates/posts.tsx'),
  //     limit: 1000,
  //     node: ['id', 'slug'],
  //     isSingle: true,
  //     listContentType: 'blogPost',
  //   },
  // },
  pages: {
    home: {
      path: '/',
      id: '#home',
      showNav: false,
      showFooterNav: false,
      showHeaderNav: false,
      displayName: '',
      navOrder: 1,
    },
    about: {
      path: '/about',
      id: '#about',
      showNav: true,
      showFooterNav: true,
      showHeaderNav: true,
      displayName: 'About',
      navOrder: 1,
    },
    works: {
      path: '/works',
      id: '#works',
      showNav: true,
      showFooterNav: true,
      showHeaderNav: true,
      displayName: 'Works',
      navOrder: 2,
    },
    contact: {
      path: '/contact',
      id: '/contact',
      showNav: true,
      showFooterNav: true,
      showHeaderNav: true,
      displayName: 'Contact',
      navOrder: 3,
    },
    notFound: {
      path: '/404',
      id: '#not-found',
      showNav: false,
      showFooterNav: false,
      showHeaderNav: false,
      displayName: '',
      navOrder: 8,
    },
  },
};
exports.getNavItems = ({ position }) =>
  Object.values(exports.models)
    .map((category) => Object.values(category))
    .reduce((arr, elm) => [...arr, ...elm])
    .filter((page) =>
      position === 'footer' ? page.showFooterNav : page.showHeaderNav
    )
    .sort((a, b) => (a.navOrder > b.navOrder ? 1 : -1));
exports.getFooterNavItems = () => exports.getNavItems({ position: 'footer' });
exports.getHeaderNavItems = () => exports.getNavItems({ position: 'header' });
exports.socialMedias = {
  // twitter: {
  //   url: 'https://twitter.com',
  //   username: 'panbanisha',
  //   icon: faTwitter,
  //   color: '#1da1f2',
  // },
  // facebook: {
  //   url: 'https://facebook.com',
  //   username: 'panbanisha',
  //   icon: faFacebook,
  //   color: '#4267B2',
  // },
};
